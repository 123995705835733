/* .my-orders {
    font-family: Arial, sans-serif;
    padding: 20px;
    max-width: 800px;
    margin: 0 auto;
  }
  
  .my-orders h1 {
    text-align: center;
    color: #333;
    margin-bottom: 20px;
  }
  
  .order {
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 15px;
    margin-bottom: 20px;
    background-color: #f9f9f9;
  }
  
  .order h2 {
    margin-top: 0;
    font-size: 18px;
    color: #007bff;
  }
  
  .order p {
    margin: 5px 0;
  }
  
  .order .items {
    margin-top: 10px;
  }
  
  .order .items ul {
    list-style-type: none;
    padding: 0;
  }
  
  .order .items li {
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 3px;
    padding: 10px;
    margin-bottom: 5px;
  }
  
  .order .items li:last-child {
    margin-bottom: 0;
  }
   */

   .my-orders {
    font-family: Arial, sans-serif;
    /* padding: 20px; */
    max-width: 800px;
    margin: 0 auto;
  }
  
  .my-orders h1 {
    text-align: center;
    color: #333;
    margin-bottom: 20px;
  }
  
  .order {
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 15px;
    margin-bottom: 20px;
    background-color: #f9f9f9;
  }
  
  .order h2 {
    margin-top: 0;
    font-size: 18px;
    color: #007bff;
  }
  
  .order p {
    margin: 5px 0;
  }
  
  .status-steps {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
  }
  
  .status-step {
    text-align: center;
    flex: 1;
    position: relative;
  }
  
  .status-step:not(:last-child)::after {
    content: '>';
    position: absolute;
    right: -15px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 24px;
    color: #ccc;
  }
  
  .circle123 {
    width: 45px;
    height: 45px;
    border-radius: 50%;
    display: inline-block;
    line-height: 30px;
    color: white;
    background-color: #6ED6F4;
    /* margin-bottom: 5px; */
    padding: 7px
  }
  
  .red {
    background-color: red;
  }
  
  .status-text {
    font-size: 12px;
  }
  
  .order .items {
    margin-top: 10px;
  }
  
  .order .items ul {
    list-style-type: none;
    padding: 0;
  }
  
  .order .items li {
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 3px;
    padding: 10px;
    margin-bottom: 5px;
  }
  
  .order .items li:last-child {
    margin-bottom: 0;
  }
  

  .order-icon {
    width: 60px;
    height: 60px;
    /* margin-right: 15px; */
}

  
@media screen and (max-width: 768px) {
  .status-text {
    font-size: 9px;
  }
}

  






.main-invoice-view{
    border: 1px solid;
    padding: 40px;
}

.main-text{
   font-size: 15px; font-weight: 600
}


.main-invoice-view-sec-1{
    width: 40%;
    word-break: break-word;
}
.main-invoice-view-sec-2{
    width: 20%;
}
.main-invoice-view-sec-3{
    width: 40%;
    word-break: break-word;

}

.main-invoice-view-sec-5{
    width: 80%;

}
.main-invoice-view-sec-4{
    width: 20%;

}

@media (max-width: 768px){
    .main-invoice-view-sec-1{
        width: 47%;
        
    }
    .main-invoice-view-sec-2{
        width: 6%;
    }
    .main-invoice-view-sec-3{
        width: 47%;
    }
    .main-invoice-view-sec-5{
        width: 60%;
    
    }
    .main-invoice-view-sec-4{
        width: 40%;
    
    }
}
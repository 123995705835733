@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap");

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Poppins", sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.main {
  margin-top: 80px; /* Add a top margin to avoid content overlay */
}

.animate__fadeInRight,
.animate__fadeInLeft,
.animate__fadeIn,
.animate__flash,
.animate__fadeIn,
.animate__swing,
.animate__backInRight,
.animate__backInLeft,
.animate__fadeInUp,
.animate__zoomIn,
.animate__animated {
  animation-timeline: view();
  animation-range: entry 100px; /* animation-iteration-count: 1; */
}

.headShake_animation {
  animation: headShake 3s ease-in;
  animation-iteration-count: 3;
}
.left_animation {
  animation: backInLeft 2s ease-in;
}
.right_animation {
  animation: backInRight 2s ease-in;
}

.zoomIn_animation {
  animation: zoomIn 2s ease-in;
}

.strong-black {
  color: black;
}

@media (max-width: 767px) {
  .mp-2 {
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .m-text-1 {
    font: 1em;
  }
  .mw-90 {
    max-width: 90% !important;
  }
  .mfs-1 {
    font-size: 0.8rem !important;
  }
  .mfs-2 {
    font-size: 1rem !important;
  }
  .mfs-3 {
    font-size: 0.5rem !important;
  }
  .m-text-left {
    text-align: left !important;
  }
}

.m-0 *{
    margin: 0;
}

@media screen and (max-width: 575px) {
  .main {
    margin-top: 130px;
  }
}
.frame1-container {
    width: 100%;
    display: flex;
    overflow: auto;
    min-height: 100vh;
    align-items: center;
    flex-direction: column;
  }
  .frame1-frame1 {
    width: 100%;
    height: 1944px;
    display: flex;
    overflow: hidden;
    position: relative;
    align-items: flex-start;
    flex-shrink: 0;
    background-color: rgba(248, 249, 251, 1);
  }
  .frame1-rectangle34624538 {
    top: 111px;
    left: 1052px;
    width: 388px;
    height: 80px;
    position: absolute;
  }
  .frame1-rectangle34624527 {
    top: 607px;
    left: 0px;
    right: 0px;
    width: 1509px;
    height: 528px;
    margin: auto;
    position: absolute;
  }
  .frame1-rectangle34624526 {
    top: 1285px;
    left: 65px;
    width: 476px;
    height: 421px;
    position: absolute;
    border-color: rgba(0, 0, 0, 1);
    border-style: solid;
    border-width: 1px;
  }
  .frame1-rectangle34624528 {
    top: 1294px;
    left: 571px;
    width: 421px;
    height: 412px;
    position: absolute;
    border-color: rgba(0, 0, 0, 1);
    border-style: solid;
    border-width: 1px;
  }
  .frame1-rectangle34624529 {
    top: 1294px;
    left: 1016px;
    width: 347px;
    height: 412px;
    position: absolute;
    border-color: rgba(0, 0, 0, 1);
    border-style: solid;
    border-width: 1px;
  }
  .frame1-rectangle34624510 {
    top: 0px;
    left: 0px;
    right: 0px;
    width: 100%;
    height: 111px;
    margin: auto;
    position: absolute;
  }
  .frame1-frame1261153112 {
    gap: 15px;
    top: 151px;
    left: 58px;
    width: 505px;
    display: flex;
    position: absolute;
    align-self: stretch;
    align-items: flex-start;
    flex-direction: column;
  }
  .frame1-frame1261153113 {
    gap: 37.7558479309082px;
    width: 505.92828369140625px;
    display: flex;
    padding: 18.8779239654541px 33.98025894165039px;
    align-items: center;
    flex-shrink: 0;
    border-color: rgba(0, 0, 0, 1);
    border-style: solid;
    border-width: 1.2585281133651733px;
    justify-content: center;
    background-color: rgba(2, 161, 229, 1);
  }
  .frame1-text10 {
    color: rgba(0, 0, 0, 1);
    height: auto;
    flex-grow: 1;
    font-size: 25.02654266357422px;
    font-style: SemiBold;
    text-align: left;
    font-family: Poppins;
    font-weight: 600;
    line-height: 123.50000143051147%;
    font-stretch: normal;
    text-decoration: none;
  }
  .frame1-frame1261153114 {
    gap: 37.7558479309082px;
    width: 505.92828369140625px;
    display: flex;
    padding: 18.8779239654541px 33.98025894165039px;
    align-items: center;
    flex-shrink: 0;
    border-color: rgba(0, 0, 0, 1);
    border-style: solid;
    border-width: 1.2585281133651733px;
    justify-content: center;
    background-color: rgba(159, 239, 248, 1);
  }
  .frame1-text11 {
    color: rgba(0, 0, 0, 1);
    height: auto;
    flex-grow: 1;
    font-size: 25.02654266357422px;
    font-style: SemiBold;
    text-align: left;
    font-family: Poppins;
    font-weight: 600;
    line-height: 123.50000143051147%;
    font-stretch: normal;
    text-decoration: none;
  }
  .frame1-frame1261153115 {
    gap: 37.7558479309082px;
    width: 505.92828369140625px;
    display: flex;
    padding: 18.8779239654541px 33.98025894165039px;
    align-items: center;
    flex-shrink: 0;
    border-color: rgba(0, 0, 0, 1);
    border-style: solid;
    border-width: 1.2585281133651733px;
    justify-content: center;
    background-color: rgba(190, 206, 55, 1);
  }
  .frame1-text12 {
    color: rgba(0, 0, 0, 1);
    height: auto;
    flex-grow: 1;
    font-size: 25.02654266357422px;
    font-style: SemiBold;
    text-align: left;
    font-family: Poppins;
    font-weight: 600;
    line-height: 123.50000143051147%;
    font-stretch: normal;
    text-decoration: none;
  }
  .frame1-frame1261153116 {
    gap: 37.7558479309082px;
    width: 505.92828369140625px;
    display: flex;
    padding: 18.8779239654541px 33.98025894165039px;
    align-items: center;
    flex-shrink: 0;
    border-color: rgba(0, 0, 0, 1);
    border-style: solid;
    border-width: 1.2585281133651733px;
    justify-content: center;
    background-color: rgba(248, 180, 115, 1);
  }
  .frame1-text13 {
    color: rgba(0, 0, 0, 1);
    height: auto;
    flex-grow: 1;
    font-size: 25.02654266357422px;
    font-style: SemiBold;
    text-align: left;
    font-family: Poppins;
    font-weight: 600;
    line-height: 123.50000143051147%;
    font-stretch: normal;
    text-decoration: none;
  }
  .frame1-frame1261153117 {
    gap: 37.7558479309082px;
    width: 505.92828369140625px;
    display: flex;
    padding: 18.8779239654541px 33.98025894165039px;
    align-items: center;
    flex-shrink: 0;
    border-color: rgba(0, 0, 0, 1);
    border-style: solid;
    border-width: 1.2585281133651733px;
    justify-content: center;
    background-color: rgba(230, 146, 208, 1);
  }
  .frame1-text14 {
    color: rgba(0, 0, 0, 1);
    height: auto;
    flex-grow: 1;
    font-size: 25.02654266357422px;
    font-style: SemiBold;
    text-align: left;
    font-family: Poppins;
    font-weight: 600;
    line-height: 123.50000143051147%;
    font-stretch: normal;
    text-decoration: none;
  }
  .frame1-group98741 {
    top: 238px;
    left: 840px;
    width: 36.00080108642578px;
    height: 7.200020790100098px;
    display: flex;
    position: absolute;
    align-items: flex-start;
    flex-shrink: 1;
  }
  .frame1-ellipse1861 {
    top: 0px;
    left: 0px;
    width: 7px;
    height: 7px;
    position: absolute;
  }
  .frame1-ellipse1871 {
    top: 0px;
    left: 14.3984375px;
    width: 7px;
    height: 7px;
    position: absolute;
  }
  .frame1-ellipse1881 {
    top: 0px;
    left: 28.80078125px;
    width: 7px;
    height: 7px;
    position: absolute;
  }
  .frame1-text15 {
    top: 40px;
    left: 1221px;
    color: rgba(84, 84, 84, 1);
    height: auto;
    position: absolute;
    font-size: 24px;
    font-style: Bold;
    text-align: center;
    font-family: DM Sans;
    font-weight: 700;
    line-height: normal;
    font-stretch: normal;
    text-decoration: none;
  }
  .frame1-text16 {
    top: 786px;
    left: 51px;
    color: rgba(70, 70, 70, 1);
    width: 632px;
    height: auto;
    position: absolute;
    font-size: 20px;
    font-style: Regular;
    text-align: left;
    font-family: Poppins;
    font-weight: 400;
    line-height: 164.9999976158142%;
    font-stretch: normal;
    text-decoration: none;
  }
  .frame1-text17 {
    top: 632px;
    left: 50px;
    color: rgba(0, 0, 0, 1);
    width: 812px;
    height: auto;
    position: absolute;
    font-size: 48px;
    font-style: SemiBold;
    text-align: left;
    font-family: Poppins;
    font-weight: 600;
    line-height: 123.50000143051147%;
    font-stretch: normal;
    text-decoration: none;
  }
  .frame1-text18 {
    top: 699px;
    left: 51px;
    color: rgba(0, 0, 0, 1);
    height: auto;
    position: absolute;
    font-size: 48px;
    font-style: Medium;
    text-align: left;
    font-family: Poppins;
    font-weight: 500;
    line-height: 123.50000143051147%;
    font-stretch: normal;
    text-decoration: none;
  }
  .frame1-text19 {
    color: rgba(0, 0, 0, 1);
  }
  .frame1-text20 {
    color: rgba(0, 160, 227, 1);
  }
  .frame1-rectangle34624511 {
    top: 1801px;
    left: 0px;
    width: 100%;
    height: 143px;
    position: absolute;
  }
  .frame1-text21 {
    top: 1810px;
    left: 0px;
    color: rgba(255, 255, 255, 1);
    right: 0px;
    width: 1317px;
    height: auto;
    margin: auto;
    position: absolute;
    font-size: 15px;
    font-style: Bold;
    text-align: left;
    font-family: Poppins;
    font-weight: 700;
    line-height: 146.00000381469727%;
    font-stretch: normal;
    text-decoration: none;
  }
  .frame1-text22 {
    font-weight: 700;
  }
  .frame1-group1707485965 {
    top: 225px;
    left: 612px;
    width: 757px;
    height: 330.27923583984375px;
    display: flex;
    position: absolute;
    align-items: flex-start;
    flex-shrink: 1;
  }
  .frame1-rectangle5944 {
    top: 54.1640625px;
    left: 0px;
    width: 757px;
    height: 276px;
    position: absolute;
    border-radius: 15.853403091430664px;
  }
  .frame1-group98742 {
    top: 276.109375px;
    left: 43.6015625px;
    width: 47.56285095214844px;
    height: 9.512070655822754px;
    display: flex;
    position: absolute;
    align-items: flex-start;
    flex-shrink: 1;
  }
  .frame1-ellipse1862 {
    top: 0px;
    left: 0px;
    width: 10px;
    height: 10px;
    position: absolute;
  }
  .frame1-ellipse1872 {
    top: 0px;
    left: 19.02356719970703px;
    width: 10px;
    height: 10px;
    position: absolute;
  }
  .frame1-ellipse1882 {
    top: 0px;
    left: 38.05078125px;
    width: 10px;
    height: 10px;
    position: absolute;
  }
  .frame1image45dr-amit11 {
    top: 0px;
    left: 470px;
    width: 288px;
    height: 330px;
    position: absolute;
  }
  .frame1-frame1000004465 {
    gap: 6.605584621429443px;
    top: 75.3046875px;
    left: 43.59375px;
    width: 299.8935241699219px;
    height: 170.42408752441406px;
    display: flex;
    position: absolute;
    align-items: flex-start;
    flex-shrink: 0;
    flex-direction: column;
  }
  .frame1-text24 {
    color: rgba(255, 255, 255, 1);
    height: auto;
    font-size: 42.27574157714844px;
    font-style: Bold;
    text-align: center;
    font-family: DM Sans;
    font-weight: 700;
    line-height: normal;
    font-stretch: normal;
    text-decoration: none;
  }
  .frame1-text25 {
    color: rgba(255, 255, 255, 1);
    height: auto;
    font-size: 21.13787078857422px;
    font-style: Regular;
    text-align: left;
    font-family: Poppins;
    font-weight: 400;
    line-height: normal;
    font-stretch: normal;
    text-decoration: none;
  }
  .frame1-text26 {
    color: rgba(255, 255, 255, 1);
    height: auto;
    font-size: 21.13787078857422px;
    font-style: Regular;
    text-align: left;
    font-family: Poppins;
    font-weight: 400;
    line-height: normal;
    font-stretch: normal;
    text-decoration: none;
  }
  .frame1-text27 {
    color: rgba(255, 255, 255, 1);
    height: auto;
    font-size: 21.13787078857422px;
    font-style: Regular;
    text-align: left;
    font-family: Poppins;
    font-weight: 400;
    line-height: normal;
    font-stretch: normal;
    text-decoration: none;
  }
  .frame1-group1707485973 {
    top: 708px;
    left: 719px;
    width: 658px;
    height: 392px;
    display: flex;
    position: absolute;
    align-items: flex-start;
    flex-shrink: 1;
  }
  .frame1-image {
    top: 0px;
    left: 0px;
    right: 0px;
    width: 658px;
    height: 392px;
    margin: auto;
    position: absolute;
    border-radius: 19px;
  }
  .frame1-group1707485984 {
    top: 37px;
    left: 15px;
    width: 635px;
    height: 318px;
    display: flex;
    position: absolute;
    align-items: flex-start;
    flex-shrink: 1;
  }
  .frame1-frame1261153123 {
    gap: 15px;
    top: 176px;
    left: 242px;
    width: 177px;
    display: flex;
    position: absolute;
    align-items: center;
    flex-shrink: 0;
    flex-direction: column;
  }
  .frame1-group1707485971 {
    width: 82px;
    height: 65px;
    display: flex;
    position: relative;
    align-items: flex-start;
    flex-shrink: 1;
  }
  .frame1-isolation-mode1 {
    top: 0px;
    left: 0px;
    width: 82px;
    height: 65px;
    position: absolute;
  }
  .frame1-text28 {
    color: rgba(255, 255, 255, 1);
    height: auto;
    font-size: 20px;
    align-self: stretch;
    font-style: SemiBold;
    text-align: left;
    font-family: Poppins;
    font-weight: 600;
    line-height: normal;
    font-stretch: normal;
    text-decoration: none;
  }
  .frame1-frame1261153122 {
    gap: 15px;
    top: 176px;
    left: 0px;
    width: 217px;
    display: flex;
    position: absolute;
    align-items: center;
    flex-shrink: 0;
    flex-direction: column;
  }
  .frame1-group1707485970 {
    width: 71px;
    height: 67px;
    display: flex;
    position: relative;
    align-items: flex-start;
    flex-shrink: 1;
  }
  .frame1-isolation-mode2 {
    top: 0px;
    left: 0px;
    width: 71px;
    height: 67px;
    position: absolute;
  }
  .frame1-text29 {
    color: rgba(255, 255, 255, 1);
    height: auto;
    font-size: 20px;
    align-self: stretch;
    font-style: SemiBold;
    text-align: center;
    font-family: Poppins;
    font-weight: 600;
    line-height: normal;
    font-stretch: normal;
    text-decoration: none;
  }
  .frame1-frame1261153119 {
    gap: 15px;
    top: 0px;
    left: 31px;
    width: 156px;
    display: flex;
    position: absolute;
    align-items: center;
    flex-shrink: 0;
    flex-direction: column;
  }
  .frame1-group1707485968 {
    width: 74px;
    height: 65px;
    display: flex;
    position: relative;
    align-items: flex-start;
    flex-shrink: 1;
  }
  .frame1-isolation-mode3 {
    top: 0px;
    left: 0px;
    width: 74px;
    height: 65px;
    position: absolute;
  }
  .frame1-text30 {
    color: rgba(255, 255, 255, 1);
    height: auto;
    font-size: 20px;
    align-self: stretch;
    font-style: SemiBold;
    text-align: left;
    font-family: Poppins;
    font-weight: 600;
    line-height: normal;
    font-stretch: normal;
    text-decoration: none;
  }
  .frame1-frame1261153120 {
    gap: 15px;
    top: 1px;
    left: 225px;
    width: 212px;
    display: flex;
    position: absolute;
    align-items: center;
    flex-shrink: 0;
    flex-direction: column;
  }
  .frame1-group1707485969 {
    width: 69px;
    height: 65px;
    display: flex;
    position: relative;
    align-items: flex-start;
    flex-shrink: 1;
  }
  .frame1-isolation-mode4 {
    top: 0px;
    left: 0px;
    width: 69px;
    height: 65px;
    position: absolute;
  }
  .frame1-text31 {
    color: rgba(255, 255, 255, 1);
    height: auto;
    font-size: 20px;
    align-self: stretch;
    font-style: SemiBold;
    text-align: left;
    font-family: Poppins;
    font-weight: 600;
    line-height: normal;
    font-stretch: normal;
    text-decoration: none;
  }
  .frame1-frame1261153124 {
    gap: 15px;
    top: 176px;
    left: 402px;
    width: 233px;
    display: flex;
    position: absolute;
    align-items: center;
    flex-shrink: 0;
    flex-direction: column;
  }
  .frame1-group1707485972 {
    width: 65px;
    height: 65px;
    display: flex;
    position: relative;
    align-items: flex-start;
    flex-shrink: 1;
  }
  .frame1-isolation-mode5 {
    top: 0px;
    left: 0px;
    width: 65px;
    height: 65px;
    position: absolute;
  }
  .frame1-text32 {
    color: rgba(255, 255, 255, 1);
    height: auto;
    font-size: 20px;
    align-self: stretch;
    font-style: SemiBold;
    text-align: center;
    font-family: Poppins;
    font-weight: 600;
    line-height: normal;
    font-stretch: normal;
    text-decoration: none;
  }
  .frame1-frame1261153121 {
    gap: 15px;
    top: 0px;
    left: 485px;
    width: 100px;
    display: flex;
    position: absolute;
    align-items: center;
    flex-shrink: 0;
    flex-direction: column;
  }
  .frame1-layer1 {
    width: 57px;
    height: 65px;
  }
  .frame1-text33 {
    color: rgba(255, 255, 255, 1);
    height: auto;
    font-size: 20px;
    align-self: stretch;
    font-style: SemiBold;
    text-align: left;
    font-family: Poppins;
    font-weight: 600;
    line-height: normal;
    font-stretch: normal;
    text-decoration: none;
  }
  .frame1-group1707485983 {
    top: 15px;
    left: 63px;
    width: 286px;
    height: 81.8226547241211px;
    display: flex;
    position: absolute;
    align-items: flex-start;
    flex-shrink: 1;
  }
  .frame1-rectangle34624522 {
    top: 0px;
    left: 0px;
    width: 286px;
    height: 81px;
    position: absolute;
  }
  .frame1hairsn11 {
    top: 0px;
    left: 18.0625px;
    width: 249px;
    height: 82px;
    position: absolute;
  }
  .frame1-text34 {
    top: 30px;
    left: 530px;
    color: rgba(40, 40, 40, 1);
    height: auto;
    position: absolute;
    font-size: 40px;
    font-style: Bold;
    text-align: center;
    font-family: DM Sans;
    font-weight: 700;
    line-height: normal;
    font-stretch: normal;
    text-decoration: none;
  }
  .frame1-text35 {
    top: 130px;
    left: 1119px;
    color: rgba(40, 40, 40, 1);
    height: auto;
    position: absolute;
    font-size: 32px;
    font-style: Bold;
    text-align: center;
    font-family: DM Sans;
    font-weight: 700;
    line-height: normal;
    font-stretch: normal;
    text-decoration: none;
  }
  .frame1-group1707485985 {
    top: 1168px;
    left: 65px;
    width: 476px;
    height: 134px;
    display: flex;
    position: absolute;
    align-items: flex-start;
    flex-shrink: 1;
    background-color: rgba(130, 206, 240, 1);
  }
  .frame1-rectangle34624524 {
    top: 0px;
    left: 0px;
    width: 476px;
    height: 134px;
    position: absolute;
    border-color: rgba(0, 0, 0, 1);
    border-style: solid;
    border-width: 1px;
  }
  .frame1-text36 {
    top: 43px;
    left: 113px;
    color: rgba(0, 0, 0, 1);
    width: 250px;
    height: auto;
    position: absolute;
    font-size: 40px;
    font-style: SemiBold;
    text-align: center;
    font-family: Poppins;
    font-weight: 600;
    line-height: 123.50000143051147%;
    font-stretch: normal;
    text-decoration: none;
  }
  .frame1-group1707485986 {
    top: 1169px;
    left: 571px;
    width: 421px;
    height: 134px;
    display: flex;
    position: absolute;
    align-items: flex-start;
    flex-shrink: 1;
    background-color: rgba(130, 206, 240, 1);
  }
  .frame1-text37 {
    top: 16px;
    left: 31.640625px;
    color: rgba(0, 0, 0, 1);
    width: 359px;
    height: auto;
    position: absolute;
    font-size: 40px;
    font-style: SemiBold;
    text-align: center;
    font-family: Poppins;
    font-weight: 600;
    line-height: 125%;
    font-stretch: normal;
    text-decoration: none;
  }
  .frame1-group1707485987 {
    top: 1168px;
    left: 1016px;
    width: 347px;
    height: 134px;
    display: flex;
    position: absolute;
    align-items: flex-start;
    flex-shrink: 1;
    background-color: rgba(130, 206, 240, 1);
  }
  .frame1-text38 {
    top: 17px;
    left: 52.1796875px;
    color: rgba(0, 0, 0, 1);
    width: 244px;
    height: auto;
    position: absolute;
    font-size: 40px;
    font-style: SemiBold;
    text-align: center;
    font-family: Poppins;
    font-weight: 600;
    line-height: 125%;
    font-stretch: normal;
    text-decoration: none;
  }
  .frame1-isolation-mode6 {
    top: 1338px;
    left: 77px;
    width: 145px;
    height: 214px;
    position: absolute;
  }
  .frame1-isolation-mode7 {
    top: 1430px;
    left: 231px;
    width: 145px;
    height: 214px;
    position: absolute;
  }
  .frame1-isolation-mode8 {
    top: 1379px;
    left: 386.3828125px;
    width: 145px;
    height: 214px;
    position: absolute;
  }
  .frame1-text39 {
    top: 1430.0234375px;
    left: 92.90234375px;
    color: rgba(40, 40, 40, 1);
    width: 108px;
    height: auto;
    position: absolute;
    font-size: 16px;
    font-style: Black;
    text-align: center;
    font-family: DM Sans;
    font-weight: 900;
    line-height: normal;
    font-stretch: normal;
    text-decoration: none;
  }
  .frame1-text40 {
    top: 1530.421875px;
    left: 234.80859375px;
    color: rgba(40, 40, 40, 1);
    width: 133px;
    height: auto;
    position: absolute;
    font-size: 16px;
    font-style: Black;
    text-align: center;
    font-family: DM Sans;
    font-weight: 900;
    line-height: normal;
    font-stretch: normal;
    text-decoration: none;
  }
  .frame1-text41 {
    top: 1484.828125px;
    left: 386px;
    color: rgba(40, 40, 40, 1);
    width: 149px;
    height: auto;
    position: absolute;
    font-size: 16px;
    font-style: Black;
    text-align: center;
    font-family: DM Sans;
    font-weight: 900;
    line-height: normal;
    font-stretch: normal;
    text-decoration: none;
  }
  .frame1-image235 {
    top: 1324px;
    left: 1030px;
    width: 319px;
    height: 292px;
    position: absolute;
    border-color: rgba(0, 0, 0, 1);
    border-style: solid;
    border-width: 1px;
  }
  .frame1-image236 {
    top: 1326px;
    left: 680px;
    width: 214px;
    height: 364px;
    position: absolute;
  }
  @media(max-width: 1386px) {
    .frame1-rectangle34624538 {
      left: 990px;
    }
    .frame1-rectangle34624527 {
      width: 100%;
    }
    .frame1-text15 {
      left: 1202px;
    }
    .frame1-text35 {
      left: 1060px;
    }
  }

/* =============== New Css =============== */
h1,h2,h3,h4,h5,h6,p{
  padding: 0;
  margin: 0;
}
ul,ol,li{
  list-style: none;
}
.bluebg{
  background: rgb(2, 161, 229);
}
.lightBluebg{
  background: rgb(159, 239, 248);
}
.yellowbg{
  background: rgb(190, 206, 55);
}
.orngebg{
  background: rgb(248, 180, 115);
}
.perpulbg{
  background: rgb(230, 146, 208);
}
.head-managereportN{
  background: rgba(0, 160, 227, 0.48);
  min-height: 65px;
  padding: 15px 30px 15px 30px;
  display: flex !important;
  justify-content: space-between;
  align-items: center;
}
.head-managereportN h2{
  font-style: normal;
    font-weight: 600;
    font-size: 25px;
    line-height: 1.2;
    display: flex;
    align-items: center;
    color: #292929;
    justify-content: center;
    text-align: center;
    max-width: 390px;
}
.head-managereportN > .head-managereport-logo {
  height: auto;
  max-width: 120px;
}
.head-managereportN p{
  color: rgb(84, 84, 84);
}
.reportAndDoctor{
  display: flex;
  gap: 6%;
}

.reportbarWrap{
  width: 47%;
  flex-direction: column;
  display: flex;
  gap: 12px;
  margin-top: 40px;
}

.reportbar{
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 10px;
  /* height: 35px; */
  border: 1.25853px solid #000000;
}

.reportbar p{
  font-family: 'Poppins';
  font-weight: 600;
  font-size: 12px;
  line-height: 1.2;
  color: #000000;
  padding: 0;
  margin: 0;
}

.doctorDetails{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  width: 47%;
}
.testerChip{
  background: rgba(129, 206, 239, 0.36);
  width: 40%;
  padding: 10px;
  text-align: center;
}
.testerChip p{
  font-size: 12px;
  line-height: 1.2;
  text-align: center;
}
.doctorDetailsInfo{
  padding: 10px;
  background: #00A0E3;
  border-radius: 15.8534px;
  position: relative;
}
.doctorDetailsInfo h3{
  font-weight: 700;
  font-size: 20px;
  line-height: 35px;
  display: flex;
  align-items: center;
  color: #FFFFFF;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.doctorDetailsInfo p{
  font-weight: 400;
    font-size: 12px;
    line-height: 22px;
    display: flex;
    align-items: center;
    color: #FFFFFF;
    flex: none;
    order: 2;
    flex-grow: 0;
}


.expartSec{
  display: flex;
  margin: 40px 0 0;
  gap: 5%;
  justify-content: center;

}

.expartInfo{
  width: 50%;
  display: flex;
  flex-direction: column;
}
.expartInfo h2{
  font-style: normal;
  font-weight: 600;
  font-size: 25px;
  line-height: 90%;
  color: #000000;
}
.expartInfo h3 span{
  color: rgb(0, 160, 227);
}
.expartInfo p{
  font-size: 12px;
  line-height: 1.2;
  padding: 0;
  margin: 0;
}

.expartList{
  width: 45%;
}

.expartListBox{
  display: flex;
  flex-direction: column;
  padding: 10px;
  min-height: 70px;
  align-items: center;
  text-align: center;
  gap: 7px;
}
.expartListBox span{
  display: block;
  font-size: 10px;
  color: white;
  font-weight: 700;
  line-height: 1.2;
}


.row.no-gutters{
  margin-left: 0;
  margin-right: 0;
}
.row.no-gutters > .col-md-4{
  padding-right: 0;
  padding-left: 0;
}
.viewportSec{
  padding: 0;
  margin: 70px 0 0;
}
.viewportSec .viewportBox{
  padding: 0 0px;
  margin: 0;
}
.viewportSec .viewportBox .topPart{
  background: #00A0E3;
  padding: 0 15px 15px;
}
.viewportSec .viewportBox .topPart .ttl{
  text-align: center;
  background: #82CEF0;
  border: 1px solid #000000;
  position: relative;
  top: -15px;
}
.viewportSec .viewportBox .topPart .ttl h2{
  height: 60px;
  background: #82CEF0;
  border: 1px solid #000000;
  font-size: 14px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
}
.viewportSec .viewportBox .bottomPart{
  padding: 0px 15px;
  margin: 0;
}
.viewportSec .viewportBox .bottomPart .bottomPartWarp{
  box-sizing: border-box;
  background: #EAF8FF;
  border: 1px solid #000000;
  height: 175px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.viewportSec .viewportBox .bottomPart .anaBoxWrap{
  display: flex;
  flex-wrap: wrap;
  padding:20px 0 0;
}

.viewportSec .viewportBox .bottomPart .anaBoxWrap .anaBox{ 
  width: 33.333333%;
  padding: 0 10px;
  position: relative;
  padding: 0 8px;
}
.viewportSec .viewportBox .bottomPart .anaBoxWrap .anaBox span{
    font-size: 5px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    font-weight: 700;
    display: block;
    width: 100%;
    padding: 0 10px;
    text-align: center;
}
.viewportSec .viewportBox .bottomPart .bottomPartWarp .thamBox{
  height: 100%;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
}
.viewportSec .viewportBox .bottomPart .bottomPartWarp .thamBox img{
  max-width: 100%;
  height: 80%;
}

.partBottomDesc{
  background: rgb(0, 160, 227);
  padding: 15px 30px 15px 30px;
}

.partBottomDesc p{
  font-size: 8px;
  line-height: 1.6;
  color: #fff;
  padding: 0;
  margin: 0;
}
.partBottomDesc p strong{
  font-weight: 700;
  color: #fff;
}


.mobileHealthOverImg{
  display: none;
  text-align: center;
}
.mobileHealthOverImg img{
  width: 100%;
  max-width: 150px;
  margin: 0 auto;
}
.speedMeterbox svg.speedometer{
  max-width: 100%;
  margin: 0 auto;
}
.speedMeterbox > div{
  text-align: center;
}

.gdPnaleMb .page3-sec-4-main{
  height: auto!important;
}
.gdPnaleMb .page3-sec-4-sub{
  height: calc(100% - 10%) !important;
}

.mbpage-4 .diet-flex {
  display: flex;
  justify-content: center;
  /* margin-top: 2em; */
  padding: 30px;
}

#report,
.mobileView{
    width: 100%;
}
.mbpage-4 .hairloss-img{
  padding: 0  !important;
  max-width: 100px;
  margin: 20px auto 30px;
}
.mbpage-4 .hairloss-img .hairloss-img1 {
  height: auto;
  width: 100%;
}
.mbpage-4 .teijsie-2{
  height: auto !important;
  background-size: 100% 100%;
  padding-bottom: 70px;
  padding-top: 50px;
}

.mbpage-4 .faq-content{
  margin-top: 0px !important;
  margin-bottom: 0 !important;
}
.mbpage-4 .food-subtxt {
  line-height: 1.2;
}
.mbpage-7 p{
  margin-inline:0;
  margin: 0;
}
.mbpage-8{
  padding: 20px;
}
.mbVidew .mobileView{
  display: none;
}

.diagnosistextPanel{
  padding: 30px 15px;
}
.diagnosistextPanel .round-box3{
  width: 100%;
}
.listSubTtl{
  padding-left: 15px;
}
@media screen and (max-width:1199px){
  .deskTView{
    display: none;
  }
  .mbVidew .mobileView{
    display: block;
  }
  .man-page-3-color-main{
    width: 100% !important;
  }

  /*================== Part 2 ============*/
  .darpanel{
    flex-direction: column !important;
  }
  .darcol{
    width: 100% !important;
    height: auto !important;
    padding: 10px 0 !important;
  }



  /*++=======++  ganagement Report Responsive ++=======++*/
  .treatPlanPanel{
    justify-content: center;
    flex-wrap: wrap;
    gap: 10px !important;
  }
  .treatPlanBox{
    width:calc(33.333333% - 30px) !important;
  }
  .modernMedicineInfo h2{
    font-size: 24px;
    font-weight: 700;
    width: 60%;
    line-height: 1.2;
    margin: 10px 0;
  }
  .modernMedicineInfo p{
    width: 60%;
    line-height: 1.4;
    font-size: 16px;
    margin-bottom: 10px;
  }


  /*==== Page 4 =====*/
  .mbpage-4 .div-img .diet-img{
    height: auto !important;
  }
  .mbpage-4 .teijsie{
    background-size: 100% 100% !important;
    height: auto !important;
    padding-bottom: 88px;
  }

  .faq-title {
    margin-top: 10px;
  }


  .mbpage-6 .bordered-container {
    padding: 10px 40px 20px 40px;
    background-size: 100% 100%;
    height: auto;
    width: 100%;
    max-width: 315px;
    position: relative;
  }
  .mbpage-6 .bordered-container .helpful-tips-container.page-helpful-tips{
    padding: 0;
    right: 0;
    bottom: -36px;
    right: -20px;
  }
  
}
@media screen and (max-width:991px){
  .deskHealthOverImg{
    display: none !important;
  }
  .mobileHealthOverImg{
    display: block;
  }
  .overHealthText{
    font-size: 12px;
    text-align: center;
  }
  .mobile-overHealtChip{
    position: relative !important;
    left: 0 !important;
    right: 0 !important;
    top: 0 !important;
    bottom: 0 !important;
    width: 100%;
    max-width: 250px;
    margin: 0 auto;
  }
  .man-page-3-color-main{
    margin: 10px 0 !important;
  }
  .conclusTham{
    margin-bottom: 20px;
  }
  .head-managereportN{
    padding: 10px;
    align-items: center;
  }
  .head-managereportN h2{
    font-size: 18px;
    line-height: 1.2;
    max-width: 215px;
    margin: 0 auto;
  }
  .head-managereportN p {
    color: rgb(84, 84, 84);
    font-size: 14px;
  }
  .reportAndDoctor{
    flex-wrap: wrap;
  }
  .reportAndDoctor .reportbarWrap{
    width: 100%;
    margin: 30px 0;
  }
  .doctorDetails{
    width: 100%;
  }
  .doctorDetailsInfo {
    margin: 70px 0 0;
  }
  .expartSec{
    flex-wrap: wrap;
  }
  .expartInfo,
  .expartList {
    width: 100%;
  }
  .expartList{
    margin-top: 30px;
  }
  .viewportSec .row{
    justify-content: center;
  }
  .viewportSec .col-md-4 {
    width: 50%;
  }
  .viewportSec .col-md-4:last-child .viewportBox{
    margin-top: 30px;
  }
  .doctorDetailsInfo h3{
    font-size: 18px;
    line-height: 1.4;
  }
  .viewportSec{
    margin: 40px 0 0;
  }
  



  .conclusionSec{
    flex-wrap: wrap;
  }
  .conclusionCol{
    width: 100% !important;
  }
  .man-page-3-color-main{
    flex-direction: column;
  }
  .head-managereportN h2 {
    font-size: 16px;
  }
  .diagnosisThreeBox.bcenter img{
    max-width: 150px;
    margin: 0 auto;
  }



  /*++=======++  ganagement Report Responsive ++=======++*/
  .treatPlanBox {
    width: calc(50% - 5px) !important;
  }
  .modernMedicineInfo p{
    width: 100%;
  }
  .mBpage2-sec-1-img{
    position: relative;
    width: 100%;
  }
  .modernMedicineInfo h2{
    width: 100%;
  }
  .page2-sec-2-headMb{
    font-size: 24px !important;
    line-height: 1.2;
  }
  .gdPnaleMb .page3-sec-4-sub{
    height: auto !important;
  }
  .gdPnaleMb{
    flex-direction: column;
    gap: 30px !important;
  }
  .gdPnaleMb .page3-sec-4-main{
    width: 100% !important;
  }
  .guiMb{
    flex-direction: column !important;
  }
  .guiMb .guiTham{
    width:100% !important;
  }
  .guiMb .guiText{
    width:100% !important;
  }
  .guiMb .guiThamimg{
    width: 100% !important;
    height: auto !important;
    max-width: 250px;
    margin: 0 auto !important;
  }

  .mbpage-4 .faq-title{
    font-size: 16px !important;
  }
  
  .mbpage-4 .breakfast-flex{
    flex-direction: column;
  }
  .mbpage-4 .bordered-container,
  .mbpage-4 .bordered-container2{
    width: 100%;
  }
  .mbpage-4 .breakfast-title{
    display: flex;
    align-items: center;
  }
  .mbpage-4 .diet-flex{
    flex-direction: column-reverse;
    padding: 20px;
  }
  .mbpage-4 .diet-content{
    width: 100%;
  }
  .mbpage-4 .div-img{
    width: 100% !important;
    text-align: center;
  }
  .mbpage-4 .diet-plan{
    margin-top: 0px;
    padding: 20px;
  }
  .mbpage-4 .foodhair-box,
  .mbpage-4 .faq-boxs{
    padding: 30px 20px !important;
    flex-direction: column;
  }
  .mbpage-4 .foodcard-img,
  .mbpage-4 .foodcard-img2,
  .mbpage-4 .faq-img,
  .mbpage-4 .faq-img{
    width: 100%;
  }
  .mbpage-4 .before-title{
    width: 100%;
    padding:0 20px;
  }

  .mbpage-6 .page-helpful-tips{
    bottom: -31px;
  }

  .mbpage-7 .content,
  .mbpage-8 .content,
  .mbpage-8 .content2{
    height: auto !important;
  }
  /* .mbpage-7 .content .content-body,
  .mbpage-8 .content .content-body{
    width: 90%;
  } */
  .mbpage-7 .content,
  .mbpage-8 .content {
    flex-direction: column;
    height: auto;
    margin-bottom: 30px;
  }
  .mbpage-7 .content .content-body,
  .mbpage-7 .content-body-reverse {
    width: 100%;
    width: 100%;
    border-radius: 15px;
  }
  .mbpage-7 .content:nth-child(odd){
    flex-direction: column-reverse;
  }
  .mbpage-7 .content .image,
  .mbpage-7 .image-reverse{
    width: 100%;
    border-radius: 0px;
    margin-bottom: 10px;
  }

  .mbpage-8 .content .content-body{
    margin-left: 0;
  }
  .mbpage-8 .content,
  .mbpage-8 .content2{
    flex-direction: column;
    width: 100%;
  }
  .mbpage-8 .content .content-body,
  .mbpage-8 .content-body-reverse,
  .mbpage-8 .content2 .content-body {
    width: 100% !important;
    border-radius: 15px;
    margin: 0;
  }
  .mbpage-8 .content .image,
  .mbpage-8 .image-reversee,
  .mbpage-8 .content .image2,
  .mbpage-8 .image-reverse2,
  .mbpage-8 .content .image3,
  .mbpage-8 .image-reverse3,
  .mbpage-8 .content2 .image3,
  .mbpage-8 .content .image4{
    width: 100%;
    border-radius: 0px;
    margin-bottom: 10px;
  }
  .mbpage-8 .content:nth-child(odd){
    flex-direction: column-reverse;
  }

  .diagnosistextPanel .management-report-pg2-treatment-plan-text38,
  .diagnosistextPanel .management-report-pg2-treatment-plan-text44{
    font-size: 18px !important;
    line-height: 1.2 !important;
  }
}
@media screen and (max-width:767px){
  .mbpage-4 .breakfast-titlebox{
    width: 75% !important;
  }
  .mbfaq-titledesignOne .faq-box2{
    margin-top: 0;
  }
}
@media screen and (max-width:575px){
  .viewportSec .viewportBox .bottomPart .anaBoxWrap .anaBox{
    padding: 0 2px;
  }
  .viewportSec .viewportBox .bottomPart .anaBoxWrap .anaBox span{
    padding: 0 5px;
  }
  .head-managereportN{
    flex-direction: column;
    justify-content: center;
    height: auto;
  }
  .head-managereportN h2{
    padding: 6px 0;
  }
  .expartList .col-4{
    width: 50%;
  }
  .viewportSec .col-md-4{
    width: 100%;
  }
  .viewportSec .col-md-4:first-child .viewportBox {
    margin-top: 0px;
  }
  .viewportSec .col-md-4 .viewportBox{
    margin-top: 30px;
  }
  .viewportSec .viewportBox .bottomPart .anaBoxWrap .anaBox span {
    font-size: 10px;
  }
  .viewportSec .viewportBox .bottomPart .bottomPartWarp{
    height: auto;
  }
  .viewportSec .viewportBox .bottomPart .anaBoxWrap,
  .viewportSec .viewportBox .bottomPart .bottomPartWarp .thamBox {
    padding: 20px 0;
  }
  .doctor-img{
    height: 140px;
  }
  .doctorDetailsInfo {
    margin: 30px 0 0;
  }
  .expartSec{
    margin-top: 30px;
  }

  /*++=======++  ganagement Report Responsive ++=======++*/
  .treatPlanBox {
    width: 100% !important;
    height: auto !important;
    gap: 0 !important;
  }
  .treatPlanTtl,
  .treatPlanTham{
    height: auto !important;
  }
  .treatPlanTham img{
    object-fit: cover;
  }
  .objectFitrMB{
    object-fit: contain;
  }
  .gdforTo{
    flex-direction: column;
    justify-content: center;
    gap: 10px;
  }
  .gdforTo > div{
    width: 100% !important;
    text-align: center !important;
  }
  .gdPnaleMb{
    padding: 30px 15px !important;
  }

  .mbpage-4 .faq-box1,
  .mbpage-4 .faq-box6{
    display: none;
  }
  .mbpage-4 .faq-box2{
    margin-left: 0;
  }
  .mbpage-4 .faq-title {
    font-size: 11px !important;
  }
  .mbpage-4 .teijsie{
    padding: 55px 50px 55px 40px;
  }

  .mbpage-6 .do-dont-section{
    flex-direction: column;
    gap: 10px;
  }
  .mbpage-6 .card{
    width: 100%;
  }
  .mbpage-6 .bordered-container{
    width: calc(100% - 30px);
  }
  .mbpage-6 .bordered-container {
    background-size: 100% 100%;
    padding: 10px 40px 16px 40px;
  }
  .mbpage-6 .bordered-container .helpful-tips-container.page-helpful-tips {
    padding: 0;
    right: 0;
    bottom: -35px;
    right: -15px;
}
  .mbpage-7 .content,
  .mbpage-8 .content {
    flex-direction: column;
    height: auto;
    margin-bottom: 30px;
  }
  .mbpage-7 .content .content-body,
  .mbpage-7 .content-body-reverse {
    width: 100%;
    width: 100%;
    border-radius: 15px;
  }
  .mbpage-7 .content:nth-child(odd){
    flex-direction: column-reverse;
  }
  .mbpage-7 .content .image,
  .mbpage-7 .image-reverse{
    width: 100%;
    border-radius: 0px;
    margin-bottom: 10px;
  }

  .diagnosistextPanel .round-box3{
    flex-direction: column;
    overflow: hidden;
    border-radius: 60px;
  }
  .diagnosistextPanel .round-img3 {
    background: linear-gradient(274deg, #00a0e3 13%, #0b82b4 73%);
    width: 100%;
    border-top-left-radius: 59px;
    border-bottom-left-radius: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    border-top-right-radius: 60px;
    padding: 10px 0;
  }
  .diagnosistextPanel .round-img3 .number-box3 {
    border: 4px solid white;
    background-color: rgb(197, 197, 197);
    border-radius: 60%;
    width: 60px;
    height: 60px;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .diagnosistextPanel .round-img3 .number-box3 .number3{
    margin: 0;
    padding: 0;
  }
  .diagnosistextPanel .box1-txt3 {
    margin-left: 0; 
    width: 100% !important;
    position: relative;
    padding: 10px 20px 20px;
  }
  .diagnosistextPanel .box1-txt3 .blue-box3 {
    margin-left: 80%;
    background: linear-gradient(274deg, #00a0e3 13%, #0b82b4 73%);
    width: 20%;
    height: 10px;
    position: absolute;
    right: 53px;
    bottom: 0;
  }
  .diagnosistextPanel{
    padding-top: 0;
  }

  .mbfaq-titledesignOne .faq-box2{
    height: 108px;
  }
  .mbfaq-titledesignOne .faq-box3,
  .mbfaq-titledesignOne .faq-box4{
    height: 65px;
  }
  .faq-titledesignTwo .faq-box2{
    height: 70px;
  }
  .faq-titledesignTwo .faq-box3,
  .faq-titledesignTwo .faq-box4{
    height: 30px;
  }
}
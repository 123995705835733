.test-link-item {
    display: flex;
    gap: 1rem;
    text-align: center;
   
}
.tab{
    flex-grow: 1;
    flex: 1;
    padding: 0.3rem 0;
    border-radius: 9px;
    color: white;
    font-weight: 600;
    font-size: 18px;
    /* display: flow-root; */
    display: flex;
    justify-content: center;
    align-items: center;
}
.tab-1,.tab-5{
    background-image: linear-gradient(to left, #3bd5e3, #3bd5e3, #3bd5e3, #3bd5e3, #3bd5e3, #48d7e4, #52d8e5, #5cdae6, #6fdee9, #7fe2eb, #8ee6ee, #9ceaf1);
}
.tab-2{
    background-image: linear-gradient(to left, #bece38, #bece38, #bece38, #bece38, #bece38, #c1d042, #c3d24b, #c6d454, #ccd967, #d2dd78, #d8e289, #dee69a);
}
.tab-3{
    background-image: linear-gradient(to left, #f7a75b, #f7a75b, #f7a75b, #f7a75b, #f7a75b, #f8ab62, #f8ae68, #f9b26f, #faba7e, #fac28c, #fbca9b, #fbd2aa);
}
.tab-4{
    background-image: linear-gradient(to left, #e692d0, #e692d0, #e692d0, #e692d0, #e692d0, #e797d2, #e89bd4, #e9a0d6, #ebaada, #eeb4df, #f0bde3, #f2c7e7);
}
.tab-1{

}
.barr1{
    height: 3rem;
    background-color: #FBD84E;
    margin-bottom: 2rem;
  }
  .hoem1{
    padding: .8rem;
  }
  .test-content-container{
    display: flex;
    margin-top: 2rem;
  }
  .test-content,.test-image{
    flex: 0 0 auto;
    width: 50%;
  }
  .test-image img{
    max-width: 100%;
    position: sticky;
    top: 90px;
    left: 0;
    z-index: 9;
  }


  .progress-indicator {
    display: flex;
    
    margin-bottom: 1.5rem;
  }
  
  .dot {
    width: 40px;
    height: 10px;
    border-radius: 19%;
    background-color:#fbd3ad;
    margin: 0 5px;
  }
  
  .dot-per {
    width: 40px;
    height: 10px;
    border-radius: 19%;
    background-color:#3BD5E3;
    margin: 0 5px;
  }

  .dot-per-1 {
    width: 40px;
    height: 10px;
    border-radius: 19%;
    background-color:#9feaf1;
    margin: 0 5px;
  }
  .dot-nut {
    width: 40px;
    height: 10px;
    border-radius: 19%;
    background-color:#dfe79b;
    margin: 0 5px;
  }
  
  .dot.active {
    background-color: #bece38;
  }
  .dot.active1 {
    background-color: #f7a75b;
  }
  .dot.active2 {
    background-color: #00c2d4;
  }
  .test-content h3{
    font-size: 1.5rem;
    color: #000000;
    width: 100%;
   
    font-weight: 500;
  }
  .options-container {
    display: flex;
    margin-top: 1rem;
    flex-wrap: wrap;
    margin-bottom: 3rem;
   
  }
  
  .option {
    margin: 10px;
    text-align: center;
    width: 100px; /* Adjusted width */
    color: #606a70;
    font-weight: 400;
    display: flex;
    flex-direction: column; /* Align items vertically */
    align-items: center; /* Center content horizontally */
    transition: all 0.3s ease 0s;
  }
  .option img{
    border: 2px solid gray;
    border-radius: 100%;
  }
  .option:hover{
    scale: 1.2;
    cursor: pointer;
  }
  .circle {
   
    height: 100px;
    border-radius: 50%;
    background-color: #dcdcdc;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1rem;
    font-weight: bold;
    
  }
  
  .option img {
    max-width: 100px; /* Adjusted image width */
    max-height: 100px; /* Adjusted image height */
  }
  
  .test-btnn{
    display: flex;
    gap: 1rem;
  }
  .test-btnn button{
    flex: 0 0 auto;
    width: 46.2%;
    padding: 1rem 0;
    border: none;
    background-image: linear-gradient(to top, #03a0d5, #03a2d7, #04a5da, #04a7dc, #07a2d1);
    color: white;
  }
  .test-btnn button:hover{
    cursor: pointer;
    background-image: linear-gradient(to top, #0488b4, #0c8bb5, #0484ae, #0589b5, #0494c0);
  }
  .selected{
    background:#d3e169;
    transition: all 0.1s ease;
    border: 3px solid #d3e169
    }
.selected-personal .circle{
  background:#9AE9F1;
  border:2px solid #9AE9F1 !important;
  height:100px;
  width:100px;
}

    .selected1{
      transition: all 0.1s ease;
      font-size: 18px;
    /* display: flow-root; */
    display: flex;
    justify-content: center;
    align-items: center;
      /* border: 3.5px solid rgb(0, 130, 20); */
      }

.no-image{
    width:100px;
    height: 100px;
    margin: 0;
    margin-bottom: .18rem;
}
.test-btnn button:disabled{
cursor: not-allowed;
background-image: none;
background-color: gainsboro!important;
color: black;
}
.profile-container input{
  display: block;
  border-top: none;
  border-left: none;border-right: none;
  width: 100%;
  margin-bottom: 2.5rem;
  background-color: #e8f6f8;
  height: 2.8rem;
  margin-top: 1rem;
  outline: none;
  border-bottom: 1px solid #03a0d5 !important;
  font-size: 1.3rem;
}
.h4{
  margin-bottom: 1rem;
}
.pcenter{
  text-align:center;
}
.cardsw{
  border: 1px solid rgb(33, 30, 30);
  margin: 1rem;
  padding: 1rem;
  position: relative;
}
.checkout-detail{
  border-top: 1px solid gray;
  margin: 1rem 0 0 0;
  padding-top: 1rem;
}

.slot{
  display:flex;
  gap:1rem;
  
}
.react-datepicker-wrapper{
  margin-bottom: 1rem;
}
.slot input{
  width: 1.5rem;
  height: 1.5rem;
}
.datecont{
  display: flex;
  flex-direction: row;
 gap: 2rem;
}
.cardsw svg{
  position: absolute;
  top: 10px;
  right: 10px;
  color: rgb(5, 73, 5);
}
.selected3{
  background:#fbeded;
 
}
.readmore{
  border-top: 1px solid gray;
  margin-top: 1rem;
  padding-top: 1rem;
  margin-bottom: 1rem;
  color: #0494c0;
}
.readmore span{
  text-decoration: underline;
}
.readmore span:hover{
  color:black;
}
.red-option{
  margin-left: 10%;
  
}
.red-option p{
  margin-top: .5rem;
  font-size: 1rem;
  margin-left: 12%;
  
}
.red-option :last-child{
  margin-bottom: 1rem;
}
.h5-tem h5{
  font-size: 1rem;
  margin-bottom: .5rem;
}
.collapsed{
  display: flex;
    gap: 1rem;
    align-items: center;
}
.cardsw , .collapsed:hover{
  cursor: pointer;
}
.specify-input{
  display: block;
  border-top: none;
  border-left: none;border-right: none;
  width: 80%;
  margin-bottom: 2.5rem;
  background-color: #e8f6f8;
  height: 2.8rem;
  margin-top: 1rem;
  outline: none;
  border-bottom: 1px solid #03a0d5 !important;
  font-size: 1.3rem;
}
.profile-container{
  margin-right: 2rem;
}
.op-con{
  margin-bottom: 1.8rem;
}
.err-con{
  position: relative;
}
.error1{
  font-size: 1.5rem;
  position: absolute;
  top: 105%;
  color: red;
}
.table {
  border-collapse: collapse;
  width: 95%;
  margin-right: 2rem;
  margin-bottom: 2.5rem;  
}
tr{
  border-bottom: 1px solid #E692D0;
  background-color: #FAE9F6;
}

.stress-table th, .stress-table td {
  padding: 1rem;
  text-align: left;
}
.stress-table td{
  font-size: 1.2rem;
}
.stress-table th {
  padding: 0;
  padding-bottom: 1rem;
  font-size: 1.1rem;
margin-right: .5rem;
background-color: white;
}

.form-check-input {
  margin-right: 0.5rem; /* Adjust as needed */
  width: 1.1rem;
  height: 1.1rem;
}
thead tr{
  border: none;
}
.form-check-input:hover{
cursor: pointer;
}
.hair-scalp{
  margin-right: 2rem;
}
/* //////////////////////////////Upload/////////////// */

.modal-content {
  padding: 50px 40px !important;
  border-radius: 8px !important;
  border: solid 1px #cccccc !important;
}

.successicon {
  text-align: justify;
  font-size: 1.1rem;
  line-height: 1.5;
  color: #303030;
  margin: 1rem 0;
}

.text-center {
  text-align: center !important;
}

.successicon p {
  color: #333333;
  padding: 15px 0;
  max-width: 675px;
  margin: 0 auto;
}

.upload-img img {
  max-width: 100%;
  height: auto;
}

.mb-3 {
  margin-bottom: 1rem !important;
}

.form-control[type=file]:not(:disabled):not([readonly]) {
  cursor: pointer;
}

.form-control[type=file] {
  overflow: hidden;
}

.form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.mb-2 {
  margin-bottom: 0.5rem !important;
}

.btn-primary {
  color: #fff;
  background-color: #0d6efd;
  border-color: #0d6efd;
}
/* //////////////////success////////////// */
.successicon p {
  color: #333333;
  padding: 15px 0;
  max-width: 675px;
  margin: 0 auto;
}
.successicon {
  text-align: justify;
  font-size: 1.1rem;
  line-height: 1.5;
  color: #303030;
  margin: 1rem 0;
}

.text-center {
  text-align: center !important;
}

.btn4:last-child {
  margin-right: 0;
}
[type=button]:not(:disabled), [type=reset]:not(:disabled), [type=submit]:not(:disabled), button:not(:disabled) {
  cursor: pointer;
}
.btn4 {
  border-radius: 0;
  display: inline-block;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 600;
  font-family: var(--ltn__heading-font);
  height: 45px;
  line-height: 45px;
  padding: 0px 25px;
  text-align: center;
  cursor: pointer;
  transition: all 0.3s ease 0s;
  position: relative;
  z-index: 1;
  margin-right: 15px;
  border-radius: 100px;
  box-shadow: none;
}
.mt-44 {
  margin-top: 1.5rem !important;
}
.btn4-primary {
  color: #fff;
  background-color: #0d6efd;
  border-color: #0d6efd;
}
/* //////////////////////////////////////////////////// */
.circle-01 {
  height: 100px;
  width: 100px;
  border-radius: 50%;
  background-color: #dcdcdc;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  font-weight: bold;
}





/* Selected styles */
.selected-personal .circle-01 {
  background: #3BD5E3;
  border: 5px solid #3BD5E3 !important;
}

.selected-personal .circle-02 {
  background: #606a70;
  border: 5px solid #606a70 !important;
}

.hide{
  display: none;
}

.selected-personal .circle-01:hover + .hide{
  display: block;
  color: grey;
  font-size: 8px;
  position: absolute;
  top:90px
}

.circle-01:hover + .hide{
  display: block;
  color: grey;
  font-size: 8px;
  position: absolute;
  top:90px
}

.circle-02:hover + .hide{
  display: block;
  color: grey;
  font-size: 8px;
  position: absolute;
  top:90px;
  z-index: 1;
}

.selected-life .circle {
  /* background:  hsl(36, 67%, 52%) ; */
  background:#F7A75B;
  /* border: 5px solid hsl(36, 67%, 52%) !important; */
  border: 5px solid #F7A75B !important;

  height:100px;
  width:100px;
}
.selected-nut .circle {
  background:#BECE38;
  border:5px solid #BECE38 !important;
  height:100px;
  width:100px;
}
.selected-personal .image-container4 {
  height: 100px;
  border: 5px solid #3BD5E3;;
  border-radius: 50%;
  width: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Ensure non-selected items retain default styles */
.option:not(.selected-personal) .circle-01 {
  background: #dcdcdc;
  border: none;
}
.gray-hair{
  margin-bottom: 2rem;
  font-size: 23px;
}
.clickable{
  font-weight: 500;
  text-decoration: underline;
  cursor: pointer;
}
/*  */

.widthpopUP{
  width: 60vw;
}
.width-sm{
  width: 150px;
}
@media (max-width: 768px) {
  .datecont{
    flex-direction: column;
  }
  .width-sm{
    width: 110px;
  }
  .widthpopUP{
    width: 88vw;
  }
.test-link-item{
  flex-wrap: wrap;
}
.hair-scalp{
  margin: 0;
}
.tab{
  
      padding: .2rem;
    
      display: flex;
      align-items: center;
      justify-content: center;

}
.test-link{
  padding: 0 .5rem;
}
.test-content-container{
  flex-direction: column;
}
.test-content{
  width: 100%;
}
.profile-container{
  margin: 10px;
}
.test-image{
  width: 100%;
  margin-top: 2rem;
}
.table {
  width: 100%;
  margin-right: 0;
}

.stress-table th, .stress-table td {
  padding: 0.5rem;
  font-size: 1rem;
}

.form-check-input {
  margin-right: 0.3rem;
  width: 0.8rem;
  height: 0.8rem;
}
}

#scrollUp {
  background-color: #EEF3F9;
  color: #071c1f;
  bottom: 70px;
  font-size: 20px;
  font-weight: bold;
  height: 40px;
  width: 40px;
  right: 3%;
  text-align: center;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  -webkit-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  box-shadow: var(--ltn__box-shadow-3);
  z-index: 99 !important;
}

#scrollUp i {
  line-height: 40px;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}


.inputBoxCust3-1 {
  background: #2e7c7b;
  border: 1px solid #e2e2e2;
  border-radius: 7px;
  cursor: "pointer";
  height: 30px;
  line-height: 15px;
  padding: 7px 16px 3px;
}


.popup-Images {
    /* height: 520px; */
    /* width: 700px; */
    background-color: #FFFFFF;
    position: absolute;
    transform: translate(-50%,-50%);
    top: 50%;
    left: 50%;
    border-radius: 10px;
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    border: 2px solid rgba(255,255,255,0.1);
    box-shadow: 0 0 40px rgb(8 7 16 / 60%);
    padding: 50px 35px;
}

.popup-Image-layout{
  border: 1px solid;
  border-radius: 17px;
  height: 70px;
  margin: 0px 0 20px 0;
}

.popup-Image-layout-1{
  border: 1px solid;
  border-radius: 50px;
  height: 70px;
  margin: 0px 0 20px 0;
}


.popup-success {
  /* height: 520px; */
  width: 300px;
  background-color: #FFFFFF;
  position: absolute;
  transform: translate(-50%,-50%);
  top: 50%;
  left: 50%;
  border-radius: 10px;
  -webkit-backdrop-filter: blur(10px);
  /* backdrop-filter: blur(10px); */
  border: 2px solid rgba(255,255,255,0.1);
  box-shadow: 0 0 40px rgb(8 7 16 / 60%);
  padding: 50px 35px;
}

.loading{
  position: fixed;
    top: 50%;
    left: 50%;
    z-index: 1000;
}

.popup-market {
  height: 50%;
  width: 40%;
  background-color: #FFFFFF;
  position: absolute;
  transform: translate(-50%,-50%);
  top: 50%;
  left: 50%;
  border-radius: 10px;
  -webkit-backdrop-filter: blur(10px);
  /* backdrop-filter: blur(10px); */
  border: 2px solid rgba(255,255,255,0.1);
  box-shadow: 0 0 40px rgb(8 7 16 / 60%);
  padding: 50px 35px;
}
@media screen and (max-width: 1199px){
  .userProfileTab .test-link-item .tab{ 
    font-size:  14px;
    line-height: 1.2;
  }
}
@media screen and (max-width: 768px){
  .popup-market{
    width: 70%;
  }
  .loading{
    left: 25%;
  }

}
@media screen and (max-width: 575px){
  .userProfileTab .test-link-item {
    flex-direction: column;
    gap: 0;
  }
  .userProfileTab .test-link-item .tab {
    font-size: 14px;
    line-height: 1.2;
    margin: 0 0 10px;
    padding: 15px;
    gap: 0;
  }
}
.head-managereport-r-page1-sec-1-left{
    /* Frame 1261153113 */

box-sizing: border-box;

/* Auto layout */
display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
padding: 15px 23px;
gap: 37.76px;

width: 100%;
height: 35px;

border: 1.25853px solid #000000;

font-family: 'Poppins';
font-style: normal;
font-weight: 600;
font-size: 14px;
line-height: 123.5%;
/* or 31px */
color: #000000;flex: none;
order: 0;
flex-grow: 0;

}

.page1-sec-3-text{
    display: flex
    ;
        justify-content: center;
        align-items: center;
        font-size: 16px;
        font-weight: 700;
        text-align: center;
        padding-top: 25px;
    
}

.page2-sec-1-img{
    position: absolute;
    top: 0;
    right: 0;
    width: 40%;
    height: 100%;
}

.page2-sec-2-head{
    font-size: 30px;
    display: flex
;
    justify-content: center;
    font-weight: 600;
}

.page3-sec-1-head{
    font-size: 14px;
    font-weight: 700;
    color: white;
    text-align: center;

}

.page3-sec-1-sub-head{
    background: rgba(10, 120, 150, 1);
    padding: 11px;
    border-radius: 20px;
    font-size: 10px;
    font-weight: 700;
    color: white;
}

.page3-sec-1-text{
    background: rgba(10, 120, 150, 1);
    font-weight: 700;
    color: white;
    padding: 0 5px;
    border-radius: 20px;
}

.page3-sec-4-main{

box-sizing: border-box;
width: 660px;
height: 800px;


background: #D7F8FF;
border: 5px solid #0996B4;
border-radius: 15px;

}

.page3-sec-4-sub{


background: #FCFCFC;
border: 1px solid #000000;
box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
border-radius: 22px;
padding: 30px 15px;
}

.page3-sec-4-last{

width: 50%;
height: 25px;
position: absolute;
/* border: 0; */
left: 25%;
bottom: -15px;
background: #0996B4;
border-radius: 111111px;

}



/* ======================== Mobile Design Start ================= */
/* .head-managereportN{
    padding: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .head-managereportN h2{
    font-size: 18px;
    line-height: 1.2;
    max-width: 215px;
    margin: 0 auto;
  }
  .head-managereportN p {
    color: rgb(84, 84, 84);
    font-size: 14px;
  } */

/* ======================== Mobile Design End ================= */
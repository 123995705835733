.main-section-3-d {
  background-image: url("https://res.cloudinary.com/drkpwvnun/image/upload/v1729429116/hair-assessment/phl0qceybiehbmkpwzri.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-color: rgba(0, 0, 0, 0.63);
}

.btn-d {
  /* height: 45px; */
  padding: 10px;
  border-radius: 16px;
  border: none;
  font-weight: 600;
  transition: all 0.3s ease 0s;
}

.cup-icon {
  height: 300px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.office-img{
  height: 400px;
}

.award-title{
  margin: 0;
}

@media (max-width: 768px) {
  .cup-icon {
    height: 150px;
  }

  .office-img{
    height: 200px !important;
  }

  .award-title{
    font-size: 7px !important;
  }

  .award-desc{
    font-size: 10px !important;
    width: 100% !important;
  }
}
